import React, { Component } from 'react';

class NoPage extends Component {
    render() { 
        return (
        <div>
            <h1> 404</h1>
            <h4>Page Not Found</h4>
        </div>
        )
    }
}
 
export default NoPage;